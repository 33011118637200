.header {
    padding: 0 0 0 0;
}

.logoInf {
    max-height: 100px;
}

.logoInf img, .logoUfrgs img {
    max-height: 100px;
}

.logoUfrgs {
    display: flex;
    justify-content: flex-end;
    max-height: 100px;
}

.title {
    font-weight: bold;
    font-size: 14px;
    color: black;
    text-align: center;
}

.title:hover {
    text-decoration: none;
    color: black;
}